
.goodsManage-wrapper {
    display: flex;
    flex-direction: column;
}
.goodsMange-top {
    padding: 20px 40px 0;
    display: flex;
    align-items: center;
    .headline {
        line-height: 40px;
        font-size: 16px;
    }
}
.addForm-container {
    padding-left: 40px;
    height: 1%;
    flex: 1;
    border-top: 1px solid #F6F5F8;
    .addForm-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    ::v-deep &.el-form--label-top {
        .el-form-item__label {
            padding: 0;
        }
    }
    .text-prompt {
        font-size: 12px;
        color: #FF0000;
        margin-left: 25px;
    }
}
::v-deep .el-input-number {
    .el-input__inner {
        text-align: left;
    }
    /*&.isError {*/
    /*    .el-input__inner {*/
    /*        border-color: #FD4446;*/
    /*    }*/
    /*}*/
}
.form-footer {
    margin: 20px 0;
    text-align: center;
}
.goodCover-wrapper {
    display: flex;
    .upload-cover {
        width: 200px;
        height: 200px;
        background: #F1F2FF;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #1E33E4;
        .file-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
            cursor: pointer;
        }
        .iconfont {
            font-size: 22px;
        }
        .file-text {

        }
    }
    .cover-wrapper {
        width: 200px;
        height: 200px;
        margin-left: 20px;
        background: #F1F2FF;
        display: flex;
        position: relative;
        img {
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
        .iconfont {
            position: absolute;
            right: -20px;
            cursor: pointer;
            font-size: 20px;
            &:hover {
                color: #ff0000;
            }
        }
    }
}
